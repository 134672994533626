$primary-color: #24D2FE;
$primary-gradient-color: linear-gradient(287.88deg, #FF1CF7 -163.3%, #00F0FF 137.14%);
$default-btn-bgc: #F5F6F7;
$fill-btn-bgc: #EAF0F6;
$login-content-bgc: #FBFDFF;
$light-primary-color: #E8F8FF;
$red-color: #DF2E2E;
$border-color: #EAEAEA;
$txt-primary-color: #243757;
$txt-secondary-color: #5D6B82;
$txt-gray-color: #7A7A7A;
$body-bgc: #F7F8FA;
$white: #FFFFFF;
$header-bgc-color: #FFFFFF;
$gray-one: #F5F6F7;
$gray-1: #7A7A7A;
$gray-2: #757575;
$gray-3: #EAEAEA;
$gray-4: #333741;
$aside-left-bgc: #222222;
$light-bgc: #333333;

